.loadingContent {
    background: transparent;
    height: 90%;
    display: flex;
    margin: auto;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}
.loadingContent .modal-content {
    text-align: center;
    background: transparent;
    border: none;
}

.modal-90w {
    height: 90%;
    margin: auto;
}

.loadingContent .spinner-border {
    color: black;
    width: 100px;
    height: 100px;
}

.loadingContent p {
    position: absolute;
    color: black;
    top: 50px;
}