.gradient-pink {
  /* background: linear-gradient(50deg, #ffebee, #f8bbd0); */
  background-color: #f8bbd0;
}

.ul-no-format {
  list-style: none;
  padding-left: 0.5rem;
}

.ul-no-format > li {
  margin-bottom: 15px;
}

.custom-select {
  font-size: 1rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  appearance: none;
}

.custom-select:focus {
  outline: none;
}

.custom-select option {
  padding: 0.5rem;
  color: black;
}

.btn_see_more {
  background: transparent linear-gradient(180deg, #56A7DB 0%, #1A82C4 100%) 0% 0% no-repeat padding-box;
  border-radius: 15px;
  padding: 5px 25px;
  color: white;
  border: none;
}

.btn_see_more:hover {
  background: #1A82C4
}