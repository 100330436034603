.background-row {
    display: flex;
    justify-content: center;
    background-color: #f0f0f0; /* Light gray background color for the row */
    padding: 20px;
    width: 100%;
  }
  
.background-row button {
    border-radius: 25px;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
.donate-button {
    background-color: #ff5733; /* Orange color for Donate Now button (charity theme) */
    margin-right: 10px;
}
  
.join-button {
    background-color: #3498db; /* Blue color for Join Us button (charity theme) */
}
  
.donate-button {
    background-color: #ff5733; /* Orange color for Donate Now button (charity theme) */
    margin-right: 10px;
}
  
.join-button {
    background-color: #3498db; /* Blue color for Join Us button (charity theme) */
}
  
  /* Hover effect for buttons */
.donate-button:hover {
    background-color: #ff824a; /* Lighter orange color on hover */
}
  
  .join-button:hover {
    background-color: #58a6ff; /* Lighter blue color on hover */
}