/* Slider Container */
.slider-container {
    position: relative;
    /* display: flex; */
    align-items: center;
    /* justify-content: center; */
    height: 100%;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden; /* Hide overflow to create the sliding effect */
  }
  /* Product Image */
  .product-image {
    height: 100%;
  }

  .product-image img {
    max-width: 100%;
    max-height: 500px;
    border-radius: 5px;
  }
  
  /* Product Details */
  .product-details {
    display: flex;
    padding: 0 20px 0px 20px;
    height: 100%;
    flex-direction: column;
  }

  .product-details h2 {
    text-align: center;
  }

  .product-details h6 {
    line-height: 1.5;
  }

  .whatsapp-container {
    /* height: 403px; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  h2 {
    margin-top: 0;
  }
  
  .price {
    font-weight: bold;
    font-size: larger;
    margin: 10px 0;
  }
  
  .whatsapp-button {
    display: block;
    padding: 10px 20px;
    background-color: #25d366;
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Slider Navigation */
  .slider-navigation {
    padding: 100px;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 0;
  }
  
  .nav-button {
    background-color: #f5f5f5;
    border: none;
    color: #333;
    font-size: 18px;
    padding: 10px 15px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .dots {
    display: flex;
    margin: 0 15px;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: #333;
  }