.slideshow-container {
    position: relative;
    max-width: 100%;
    /* max-height: 600px; */
    height: calc(100vh - 108px); 
    overflow: hidden;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: zoomIn 3s forwards;
  }
  
  .slide-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Keeps the image's aspect ratio, letterboxing if necessary */
  }
  
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    user-select: none;
  }
  
  .prev {
    left: 0;
  }
  
  .next {
    right: 0;
  }
  
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .slideshow-container p {
    position: absolute;
    bottom: 0;
    color: white;
    width: 100%;
    text-align: center;
    font-size: 18px;
    padding: 8px 12px;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }

  @keyframes zoomIn {
    0% {
      transform: scale(1.1);
      opacity: 0;
    }
    100% {
      transform: scale(1); /* Adjust the scaling factor for the zoom effect */
      opacity: 1;
    }
  }